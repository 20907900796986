.main-footer {
  color: white;
  background-color: #0f0f0f;
  position: relative;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
}
.main-foot {
  padding-top: 100px;
  padding-bottom: 80px;
}
.main-footer hr {
  border: 1px solid white;
  margin: 0 !important;
}
.address-foot {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #bebebe;
}
.address-foot img{
margin-bottom: 10px;
width: 80px;
height: auto;
}
.address-foot p {
  color: gray;
  font-family: "eudoxus sans";
  text-align: left;
}
.address-foot-2 p {
  color: #bebebe;
  font-family: "eudoxus sans";
}
.address-foot-2 {
  text-align: left;
  border-right: solid 1px #bebebe !important;
}
.address-foot-2 h4 {
  font-family: "proxima-nova";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #717171;

  }
  .address-in-footer{
    margin-bottom: 0;
  }
.address-foot h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #bebebe;
}
.address-foot h5 {
  font-weight: 400;
  text-align: left;
}
.address-sec {
  border-right: solid 1px #bebebe !important;
}
.quick-links {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  
}
.quick-links h4{
  font-family: "eudoxus sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #bebebe !important;

}
.quick-links a {
  text-decoration: none !important;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
  display: flex;
}
.quick-links .dropdown-toggle::after{
  margin-top: 4%;
}
.quick-links:hover {
  color: var(--Grey, #bebebe);
}

.link-hover {
  margin-top: 20px;
  width: 100%;
  filter: grayscale(1) invert(100%);
}
.link-hover:hover {
  filter: none;
}
.off-hover {
  text-decoration: none !important;
  color: white !important;
  text-align: left;
  width: 100%;
  color: var(--Grey, #bebebe);
  margin-top: 20px;
}

.list-unstyled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.list-unstyled li {
  margin-right: 10px;
}
.list-styled {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  list-style: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.list-styled li {
  padding-right: 20px;
}
.list-styled a {
  height: 20px;
  width: auto;
}
.link-hover {
  height: 30px;
  width: auto;
}
.qls {
  background-color: #0f0f0f;
  border-right: solid 1px #bebebe !important;
}
.third-text h6 {
  text-align: left;
  font-family: "proxima-nova";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #717171;
}
.quick-links h6{
  width: 100%;
  text-align: left;
  font-family: "proxima-nova";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #717171;
}


@media only screen and (max-width: 768px) {
  .main-footer {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  .main-footer hr {
    border: 1px solid white;
    margin: 0 !important;
    width: 100%;
  }
  .quick-links {
    margin-left: 0;
  }
  .third-text {
    text-align: left;
    margin-bottom: 20px;
  }
  .address-foot-2 {
    padding-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .main-footer {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-footer hr {
    border: 1px solid white;
    margin: 0 !important;
    width: 100%;
  }
  .third-text {
    text-align: left;
    margin-bottom: 20px;
  }
  .quick-links {
    margin-left: 0;
  }

  .address-foot-2 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 468px) {
  .carousel-titles {
    margin-bottom: 15px !important;
  }
  .list-unstyled p {
    font-size: 14px;
  }
  .main-foot {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .slick-slider button {
    display: none !important;
  }
  .address-foot-2 {
    padding-left: 0;
  }
}
