/* @import "https://fonts.cdnfonts.com/css/bradley-hand-2"; */
/* .cursor {
  position: fixed;
  width: 30px;
  height: 30px;
  background-color: white;
  border: 2px solid #0f0f0f;
  border-radius: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  pointer-events: none;
} */
/* @font-face {
  font-family: "proxima-nova";
  src: url(https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("woff"),
    url(https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "eudoxus sans";
  src: url(https://stijndv.com/fonts/EudoxusSans-Regular.woff2) format("woff2"),
    url(https://stijndv.com/fonts/EudoxusSans-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
  font-display: swap;
}
@font-face {
  font-family: Branley;
  src: url(./font/Branley.ttf);
  font-display: swap;
} */
html {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
/* h1{
  font-family: proxima-nova;
} */
/* body {
  scroll-behavior: smooth;
} */

/* * {
  cursor: none;
} */
.element {
  content: "This is a \/ solution";
}

/*
.a:hover +.cursor{
  transform: scale(5);
}
*/
@media only screen and (min-width: 991px) and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cursor {
    display: none;
  }
}

@media only screen and (min-width: 468px) and (max-width: 768px) {
  .cursor {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 468px) {
  .cursor {
    display: none;
  }
}
