.twosideslider {
  width: 100%;
  margin: auto;
}

.dual-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.image-container img {
  max-width: 100%; 
  height: auto;
}

.text-container {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  flex: 1;
  order: 1;
}

.text-container h1 {
  font-family: Proxima Nova;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #FBB13C;
}

.text-container p {
  font-family: Proxima Nova;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #0F0F0F;
}

.text-container span {
  color: #BEBEBE;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}