@import url(https://fonts.cdnfonts.com/css/satoshi);
body {
  margin: 0;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fbfbfb;
  color: #000;
  padding: 10px 20px;
  box-shadow: 0 5px 20px rgba(29, 29, 29, 0.05);
  position: static;
  opacity: 1;
}
.nav__toggle-btn {
  position: absolute;
  right: 20px;
  top: 25px;
}
.right-navbar {
  display: flex;
  justify-content: end;
}
.navbar::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
#navbar2::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white !important;
  z-index: -1;
  box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.25);
}
.logo img {
  width: 50%;
  margin: 10% 0 0 25%;
}
.nav-link {
  padding: 0 !important;
  font-family: "Branley" !important;
  font-size: 1.1rem;
  font-weight: 500;
}
.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  flex: 1;
}
.nav-links li {
  margin: 0 20px;
}
.nav-links a {
  font-family: "Branley";
  text-decoration: none !important;
  font-weight: 400;
  color: #0f0f0f;
  position: relative;
}
.nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #0f0f0f;
  transition: width 0.35s ease-out;
}
.nav-link2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #0f0f0f !important;
  transition: width 0.35s ease-out;
}
.nav-link:hover::before {
  width: 100%;
}
.nav-link-mob::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: width 0.35s ease-out;
}
.nav-link-mob:hover::before {
  width: 100%;
}
.nav-links a:hover {
  color: #0f0f0f;
  width: 100%;
}
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.navbar.hidden {
  position: absolute;
  opacity: 0;
}
#navbar-toggler {
  border: none;
}
.navbar-dark .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.drop-1 {
  font-family: "Branley";
  font-size: 1rem;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
}
.drop-full {
  background: transparent;
  border: none !important;
}
.navbar-dark .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}
.navbar-dark .navbar-toggler[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}
.navbar-dark .navbar-toggler[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}
.navbar-dark .navbar-toggler[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}
.navbar-light .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background-color: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-light .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}
.navbar-light .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}
.navbar-light .navbar-toggler:hover span,
.navbar-light .navbar-toggler:focus span {
  background: #ccc;
}
.navbar-dark .navbar-toggler:focus {
  background: none;
  outline: 0;
}
.navbar-dark .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.navbar-dark .navbar-toggler:hover span,
.navbar-dark .navbar-toggler:focus span {
  background: #ccc;
}
.navbar-dark .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}
.navbar-dark .navbar-toggler[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}
.navbar-dark .navbar-toggler[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}
.navbar-dark .navbar-toggler[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}
.nav-link-mob {
  display: none;
  opacity: 0;
}
.navbar2 {
  background-color: #fff;
}
.main-navbar-2 {
  background-color: #fff;
}
.navbar.visible.transparent-bg.expand-navbar::before {
  background: #181717ee;
}
.navbar3 {
  background-color: #fff;
  height: 12%;
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
}
.phone {
  margin-right: 25px;
  width: 45%;
}
.mail {
  margin-right: 20px;
  width: 55%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-links li {
    margin-bottom: 25px;
  }
  .nav-link-mob {
    display: none;
    opacity: 0;
  }
}
@media only screen and (min-width: 468px) and (max-width: 768px) {
  .nav-links li {
    margin-bottom: 25px;
  }
  .right-navbar {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 40px;
  }
  .nav-link-mob {
    display: block;
    opacity: 0;
  }
  .mobile-view {
    display: inline-block;
  }
  .desktop-view {
    display: none;
  }
  .nav-link-icon-mail {
    position: absolute;
    right: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 468px) {
  .nav-link-icon-mail {
    position: absolute;
    right: 0;
  }
  .mail {
    margin-bottom: 20%;
  }
  .phone {
    margin-bottom: 20%;
  }
  .right-navbar {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 40px;
  }
  .nav-links li {
    margin-bottom: 25px;
    text-align: center;
  }
  .drop-1 {
    text-align: center !important;
    padding-bottom: 10px !important;
  }
  .nav-link-mob {
    display: block;
    opacity: 0;
  }
  .hamburger {
    display: none;
  }
  .navbar3 {
    height: 12%;
  }
  .mobile-view {
    display: inline-block;
  }
  .desktop-view {
    display: none;
  }
  #navdrop {
    text-align: center !important;
  }
}
